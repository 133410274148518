<template>
  <q-page padding class="layout-padding" style="max-width: 768px">
    <div class="row">
      <date-field v-model="range.from" color="#394154" :max="range.to" :label="$t('from')" class="col-5 no-margin" />
      <div class="col-2" />
      <date-field v-model="range.to" color="#394154" :max="today" :label="$t('until')" class="col-5 no-margin" />
    </div>
    <q-card v-if="items.length > 0" class="bg-white">
      <q-list>
        <div v-for="(item, index) in items" :key="index">
          <q-item>
            <q-item-section avatar>
              <m-icons-type :type="item.type.toLowerCase()" size="48px" />
            </q-item-section>
            <q-item-section>
              <q-item-label label>
                {{ item.content }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label label>
                {{ item.amount.text }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-separator />
        </div>
      </q-list>
      <q-card-actions align="right">
        <q-btn flat size="md" color="primary" :label="$t('download_csv')" @click="expensesCSV(range)" />
      </q-card-actions>
    </q-card>
    <m-empty-state v-else />
  </q-page>
</template>

<script type="text/javascript">
import dateField from '@/MFields/date'
import date from 'utils/date-time'
import { expenses, expensesCSV } from 'api/expenses'
import travelContents from 'mixins/travelContents'
// import peekForm from 'peeks/peekForm.vue'

export default {
  name: 'Expenses',
  components: {
    dateField
  },
  data () {
    let today = date.newDate(),
      month = today.startOf('month')
    return {
      today: today,
      items: [],
      travelContents: travelContents.data().travelContents,
      range: {
        to: date.toCivilDateTime(month),
        from: date.toCivilDateTime(date.subtractFromDate(month, { month: 1 }))
      }
    }
  },
  watch: {
    range: {
      deep: true,
      immediate: true,
      handler (value, old) {
        expenses({
          from: date.toCivilDate(this.range.from),
          to: date.toCivilDate(this.range.to)
        })
          .then(res => {
            this.items = res.data.expenses.data
            this.month = res.data.title
          })
      }
    }
  },
  methods: {
    expensesCSV: (range) => {
      expensesCSV({
        from: date.toCivilDate(range.from),
        to: date.toCivilDate(range.to)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card
    margin 16px 0 0 !important
</style>
